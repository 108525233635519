import CourseList from "./CourseList";
import IbbuHeader from "./IbbuHeader";
import StudentInfo from "./StudentInfo";
import "./index.scss";
import QRCodeGenerator from "../../shared/components/QRCodeGenerator";

const FrontPage = ({ examCardDetails }: any) => {
  const { studentUniqueId, currentSession, currentSemester } = examCardDetails;
  const qrCodeValue = `https://ibbuportals.ibbu.edu.ng/verify?code=${studentUniqueId}&session=${currentSession}&type=EXAMCARD&semester=${currentSemester}`;

  return (
    <div className="front-page-wrapper">
      <IbbuHeader />
      <StudentInfo studentInfo={examCardDetails} />
      <CourseList studentInfo={examCardDetails} />

      {/* Signature */}
      <div className="sign">
        <p>Examination Officer's Signature & Stamp :</p>
        <hr />
      </div>

      {/* NOTES */}
      <div className="note">
        <div>
          <h5>NOTE:</h5>
          <p>
            You will not be allowed to sit for any paper without the
            presentation of this card. This card must be printed in colors to
            make it valid. Black & White copy will not be accepted.
          </p>
        </div>
        <QRCodeGenerator value={qrCodeValue} />
      </div>
    </div>
  );
};

export default FrontPage;
