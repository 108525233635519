import { useRef, useState, useEffect } from "react";
import  { useReactToPrint } from "react-to-print";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { connect } from "react-redux";

import { UndergraduateAdmissionStatusChecker } from "../../../../../shared/templates/admissions/undergraduate/index";
import { StepGuide } from "./step-guide";
import { AlertMsg } from "../../../../../shared/components/alert-msg";
import Congrats from "../../../../../assets/congrats.png";

const AdmissionOffered = ({ pageProps, setIsToPrint, isToPrint }: any) => {
  let admissionData = pageProps?.admissionData;
  const screenResolution: any = useWindowSize();

 
  return (
    <div className="status_check_wrap">
      <div className="page_heading">Check Admission Status (UTME/DE) {pageProps?.admissionData?.sessionData?.session} session </div>
      <StepGuide step={1} isToPrint={isToPrint} />
      <div className="other_info_wrap with-br">
        <div className="congrats_img">
          <img src={Congrats} alt="" />
        </div>
        <div className="status_heading">
          YOUR APPLICATION FOR ADMISSION INTO IBRAHIM BADAMASI BABANGIDA
          UNIVERSITY FOR THE  {admissionData?.session} SESSION WAS SUCCESSFUL.{" "}
        </div>
        <div className="student_details">
          <div>JAMB (UTME/DE) REG No: {admissionData?.registrationNumber} </div>
          <div>
            {" "}
            Name : {admissionData?.surname} {admissionData?.firstName}{" "}
            {admissionData?.otherNames}{" "}
          </div>
          <div> Course Offered : {admissionData?.programme} </div>
          <div> Session : {admissionData?.session} </div>
        </div>
        {admissionData?.isAdmitted && (
          <>
            <div className="payment-info">
              <div className="heading-info">INSTRUCTION TO ALL NEWLY ADMITTED STUDENTS ON HOW TO PROCEED</div> 
               <div className="payment_help_txt">
                        All newly admitted students are hereby notified that they are to pay a N25,300 Naira (UTME) or N30,300 Naira (DE) acceptance fee before they can print their admission notification letter and other related admission documents. 
                        <br />Acceptance fee payment can be paid online or at any bank and confirmed before you can proceed. Upon confirmation of your payment please visit this page and provide your UTME registration number to proceed.
                        <br />Please ensure that the email address provided during invoice generation are valid, as payment notifications will be sent to it once payment is confirmed.
                    </div> 
            </div>
          </>
        )}
        {!isToPrint && (
          <div className="proceed_wrap two">
            <div className="nav_back">
              <span
                onClick={() => {
                  pageProps?.setShowAdmissionCongrats(false);
                  pageProps?.setShowLanding(true);
                }}
              >
                {" "}
                Back
              </span>
            </div>
            {admissionData?.isAdmitted == true ? (
              <button
                className="btn"
                onClick={() => {
                  pageProps?.setShowAdmissionCongrats(false);
                  pageProps?.setShowAcceptance(true);
                }}
              >
                Proceed
              </button>
            ) : null}
          </div>
        )}
        {admissionData?.isAdmitted == false && (
          <div className="justify-center">
            <AlertMsg
              type="error"
              message={`${admissionData?.notApprovedReason}`}
            />
          </div>
        )}
        {
          admissionData?.isAdmitted == false && admissionData?.notApprovedReason &&
          <div className="proceed_utme">
            <a href="https://postutme.ibbu.edu.ng/login" target="_blank">Proceed to update your records</a>
          </div>
        }
        {!isToPrint && screenResolution?.width >= 1025 && (
          <div className="nav_back">
            <span
              onClick={() => {
                setIsToPrint(true);
              }}
            >
              {" "}
              Print{" "}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const AdmissionOfferedWrap = (pageProps: any) => {
  const [isToPrint, setIsToPrint] = useState<boolean>(false);
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Admissions",
    onAfterPrint: () => setIsToPrint(false),
  });

  useEffect(() => {
    if (isToPrint) {
      
      handlePrint();
    }
  }, [isToPrint]);
  return (
    <div
      ref={componentRef}
      style={isToPrint ? { width: "100%", height: window.innerHeight } : {}}
    >
      <UndergraduateAdmissionStatusChecker
        childComponent={
          <AdmissionOffered
            isToPrint={isToPrint}
            setIsToPrint={setIsToPrint}
            pageProps={pageProps}
          />
        }
      />
    </div>
  );
};

const mapDispatchToProps = {};
const mapStateToProps = (state: any) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdmissionOfferedWrap);
