import { connect } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import generatePDF from "react-to-pdf";
import { UndergraduatePortal } from "../../../shared/templates/portal/undergraduate";
import DashUsrIco from "../../../assets/user.png";
import UsrIco from "../../../assets/user2.png";
import ExamCard from "../../../assets/exam.png";
import Print from "../../../assets/Print.png";
import Result from "../../../assets/result.png";
import PayIco from "../../../assets/card-pay.png";
import House from "../../../assets/House.png";
import PDF from "../../../assets/pdf.png";
import ExportIc from "../../../assets/export.png";
import CloseIc from "../../../assets/close.png";
import Modal from "react-bootstrap/Modal";

import { useWindowSize } from "../../../hooks/useWindowSize";
import { AlertMsg } from "../../../shared/components/alert-msg";
import { LoadingItem } from "../../../shared/components/loading";
import { uGradPortal } from "../../../redux/action/portal/undergraduate";
import { appConstantsUGradOPortal } from "../../../redux/action-constants/portal/undergraduate";
import "./index.scss";
import InfoModal from "../admissions/check-admission-status/components/InfoModal";
import { handleRequestErrors } from "../../../shared/utils";
import {
  checkEligibility,
  getCurrentSchoolSession,
} from "../../../redux/action/hostels";

const getUnderGradProfileActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.getUnderGradProfile(payload);
};

const getUnderGradExamCardActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.getUnderGradExamCardReg(payload);
};

const getUnderGradAcceptanceFeeReceiptActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.getUnderGradAcceptanceFeeReceipt(payload);
};

const ExamCardDownload = ({
  showExamCard,
  setShowExamCard,
  dashboardRequestData,
  pageProps,
}: any) => {
  let printCardRequest = pageProps.UGradExamCardRequest;
  // useEffect(() => {
  //   let payload = {
  //     matricNumber: dashboardRequestData?.matricNumber,
  //     session: dashboardRequestData?.currentSession,
  //     studentId: dashboardRequestData?.studentUniqueId
  //   };

  //   getUnderGradExamCardActionRequest({
  //     pageProps,
  //     payload,
  //   });
  // }, []);

  return (
    <Modal
      className="examcard-modal"
      show={showExamCard}
      onHide={() => setShowExamCard(false)}
    >
      <Modal.Body>
        {printCardRequest?.request_status ===
          appConstantsUGradOPortal?.PRINT_EXAM_CARD_PENDING && (
          <>
            <LoadingItem />
          </>
        )}

        {printCardRequest?.request_status ===
          appConstantsUGradOPortal?.PRINT_EXAM_CARD_FAILURE && (
          <AlertMsg
            type="error"
            message={printCardRequest?.request_data.error}
          />
        )}
        {printCardRequest?.request_status ===
          appConstantsUGradOPortal?.PRINT_EXAM_CARD_SUCCESS && (
          <div className="message_wrap">
            Please click{" "}
            <a
              href={printCardRequest?.request_data?.response}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              here to download your exam card
            </a>
          </div>
        )}
        <button
          className="btn close_now"
          onClick={() => setShowExamCard(false)}
        >
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export const DownloadModal = ({
  setShowDwModal,
  isDwModalShown,
  downloadLink,
}: any) => {
  let link = downloadLink.split("/").pop();
  if (link.length > 15) {
    link =
      link.substr(0, 5) + "..." + link.substr(link.length - 10, link.length);
  }
  return (
    <Modal
      className="download_container"
      show={isDwModalShown}
      onHide={() => setShowDwModal(false)}
    >
      <Modal.Body>
        <div className="download_wrap">
          <img
            src={CloseIc}
            alt=""
            className="close_cta"
            onClick={() => setShowDwModal(false)}
          />
          <div className="export_ic">
            <img src={ExportIc} alt="" />
          </div>
          <div className="link_wrap">
            <img src={PDF} alt="" />
            <div className="link_txt">{link}</div>
          </div>
          <div className="dw_cta">
            <a target="_blank" href={downloadLink} rel="noreferrer">
              Download
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const DashboardContent = ({
  dashboardRequestData,
  pageProps,
  showExamCard,
  setShowExamCard,
  downloadAcceptanceReceipt,
  isEligible,
  showGST,
  isHostelOpen,
  isMedicalStudentMsg,
}: any) => {
  const [isMoreShown, setShowMore] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showNoSpaceModal, setShowNoSpaceModal] = useState(false);
  const [infoMsg, setInfoMsg] = useState<string>("");
  const screenResolution: any = useWindowSize();
  const [showExamCardErrorAlert, setShowExamCardErrorAlert] =
    useState<boolean>(false);
  const [downloading, setDownloading] = useState(false);

  const [isDwModalShown, setShowDwModal] = useState<boolean>(false);
  let acceptanceReceiptRequest = pageProps?.UGradGenerateAcceptanceFeeRequest;
  let entrepreneurFeeExemptionList = [
    { programme: "FOOD SCIENCE AND TECHNOLOGY", level: "400" },
    { programme: "AGRICULTURE", level: "400" },
  ];
  const matricNumber =
    pageProps?.uGradLoginRequest?.request_data?.matricNumber || "";

  const formattedMatricNumber = matricNumber.replace(/\//g, "");
  const examCardError = pageProps?.UGradExamCardRequest;
  const { hasBookedHostelAccommodation, hasPaidHostelAccommodationFee } =
    dashboardRequestData;
  let isEntrepreneurFeeExempted = entrepreneurFeeExemptionList.filter(
    (eachItem) =>
      eachItem.programme.toLowerCase() ===
        dashboardRequestData?.programme.toLowerCase() &&
      eachItem.level === dashboardRequestData?.level
  );
  let skipGST =
    dashboardRequestData.level === "400" ||
    dashboardRequestData.level === "500" ||
    dashboardRequestData.level === "600" ||
    dashboardRequestData.level === "700";
  let confirmNanissFee: boolean = false;
  let canPrintExamCard: boolean = false;

  if (
    (dashboardRequestData?.state &&
      dashboardRequestData?.state.toLowerCase() === "niger" &&
      dashboardRequestData?.hasPaidNanissFee) ||
    (dashboardRequestData?.state &&
      dashboardRequestData?.state.toLowerCase() !== "niger")
  ) {
    confirmNanissFee = true;
  }

  if (
    dashboardRequestData?.state &&
    dashboardRequestData?.state.toLowerCase() === "niger" &&
    !dashboardRequestData?.hasPaidNanissFee
  ) {
    confirmNanissFee = false;
  }
  if (
    dashboardRequestData?.hasPaidSchoolFee &&
    dashboardRequestData?.hasPaidFacultyFee &&
    (skipGST || dashboardRequestData?.hasPaidGstFee) &&
    ((isEntrepreneurFeeExempted.length === 0 &&
      dashboardRequestData?.hasPaidEntrepreneurshipFee) ||
      isEntrepreneurFeeExempted.length >= 1) &&
    dashboardRequestData?.hasPaidSugFee &&
    confirmNanissFee
  ) {
    canPrintExamCard = true;
  }
  const options: any = {
    filename: `${formattedMatricNumber}-exam-card.pdf`,
    page: {
      orientation: "landscape",
    },
  };

  const getTargetElement = (): HTMLElement | null =>
    document.getElementById("table-to-export");

  const downloadPdf = async () => {
    setDownloading(true);
    await generatePDF(getTargetElement, options);
    setDownloading(false);
  };

  useEffect(() => {
    if (
      acceptanceReceiptRequest?.request_status ===
      appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_SUCCESS
    ) {
      setShowDwModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptanceReceiptRequest?.request_status]);

  return (
    <div className="dashboard_content">
      <InfoModal show={show} setShow={setShow} message={infoMsg} />
      <InfoModal
        show={showNoSpaceModal}
        setShow={setShowNoSpaceModal}
        message={
          !isHostelOpen
            ? "Hostel accommodation booking has not yet opened."
            : isMedicalStudentMsg?.length
            ? isMedicalStudentMsg
            : "No bedspace available for your level."
        }
      />
      {showExamCard && (
        <ExamCardDownload
          pageProps={pageProps}
          dashboardRequestData={dashboardRequestData}
          showExamCard={showExamCard}
          setShowExamCard={setShowExamCard}
        />
      )}
      {isDwModalShown && (
        <DownloadModal
          downloadLink={acceptanceReceiptRequest?.request_data?.response}
          isDwModalShown={isDwModalShown}
          setShowDwModal={setShowDwModal}
        />
      )}
      <div className="dahboard_siderbar">
        <div className="student_img">
          <img src={dashboardRequestData?.photoId || DashUsrIco} alt="" />
        </div>
        <div className="top_info">
          <div className="info_wrap">
            <div className="info_title">Name :</div>
            <div className="info_data">{dashboardRequestData?.fullName}</div>
          </div>
          <div className="info_wrap">
            <div className="info_title">Matric Number :</div>
            <div className="info_data">
              {dashboardRequestData?.matricNumber}
            </div>
          </div>
          {dashboardRequestData?.registrationNumber && (
            <div className="info_wrap">
              <div className="info_title">JAMB Reg No. :</div>
              <div className="info_data">
                {dashboardRequestData?.registrationNumber}
              </div>
            </div>
          )}
          {screenResolution?.width <= 766 && !isMoreShown && (
            <div className="show_more" onClick={() => setShowMore(true)}>
              Show more ..
            </div>
          )}
        </div>

        {(screenResolution?.width >= 767 || isMoreShown) && (
          <div className="other_info_wrap">
            <div className="info_content">
              <div className="info_title">Academic session :</div>
              <div className="info_data">
                {dashboardRequestData?.currentSession}
              </div>
            </div>
            <div className="info_content">
              <div className="info_title">Current Semester:</div>
              <div className="info_data">
                {dashboardRequestData?.currentSemester}
              </div>
            </div>
            <div className="info_content">
              <div className="info_title">Course of Study :</div>
              <div className="info_data">{dashboardRequestData?.programme}</div>
            </div>
            <div className="info_content">
              <div className="info_title">Current Level:</div>
              <div className="info_data">{dashboardRequestData?.level}</div>
            </div>
            <div className="info_content">
              <div className="info_title">Department :</div>
              <div className="info_data">
                {dashboardRequestData?.department}
              </div>
            </div>
            <div className="info_content">
              <div className="info_title">Faculty :</div>
              <div className="info_data">{dashboardRequestData?.faculty}</div>
            </div>
            {/* <div className="info_content">
                            <div className="info_title">Student Status :</div>
                            <div className="info_data">LOREM IPSUM</div>
                        </div> */}
            <div className="info_content">
              <div className="info_title">School Fees Status :</div>
              <div className="info_data">
                {dashboardRequestData?.hasPaidSchoolFee ? "Paid" : "Not Paid"}
              </div>
            </div>
            <div className="info_content">
              <div className="info_title">Course Reg. Status :</div>
              <div className="info_data">
                {dashboardRequestData?.hasRegisteredForCurrentSession
                  ? "Completed"
                  : "Not Registered"}
              </div>
            </div>
            {/* <div className="info_content">
                            <div className="info_title">Hostel Status :</div>
                            <div className="info_data">{dashboardRequestData?.hasHostelAccommodation ? "Hostel assigned" : "Not assigned"}</div>
                        </div> */}
            {screenResolution?.width <= 766 && isMoreShown && (
              <div className="show_more" onClick={() => setShowMore(false)}>
                Hide details ..
              </div>
            )}
          </div>
        )}
      </div>
      <div className="dashboard_items">
        {!dashboardRequestData?.hasUpdatedBioData &&
          dashboardRequestData?.level !== "500" &&
          dashboardRequestData?.programme.toLowerCase() !== "agriculture" &&
          dashboardRequestData?.programme.indexOf("FEA/SED") < -1 && (
            <div className="required_action_alert">
              You <span>must update your bio-data</span> before you can take any
              action on your portal.
              <Link to="/undergraduate/personal-data/update">
                Cick here to update
              </Link>
            </div>
          )}

        {String(dashboardRequestData?.level) === "500" &&
          dashboardRequestData?.programme.toLowerCase() === "agriculture" &&
          !dashboardRequestData?.hasUpdatedBioData && (
            <div className="required_action_alert">
              All 500 Level Students of Agriculture{" "}
              <span>must update bio-data and choose programme of study</span>{" "}
              before you can take any action on your portal. <br />
              <Link to="/undergraduate/personal-data/update">
                Cick here to update
              </Link>
            </div>
          )}

        {dashboardRequestData?.programme.indexOf("FEA/SED") > -1 &&
          !dashboardRequestData?.hasUpdatedBioData && (
            <div className="required_action_alert">
              All Students of Science Education{" "}
              <span>must update bio-data and choose programme of study</span>{" "}
              before you can take any action on your portal. <br />
              <Link to="/undergraduate/personal-data/update">
                Cick here to update
              </Link>
            </div>
          )}
        <div className="each_dashboard_section">
          {/* EXAM CARD ALERT ERROR */}
          {examCardError?.request_data?.error?.length &&
          showExamCardErrorAlert ? (
            <div className="justify-center">
              <AlertMsg
                type="error"
                message={examCardError?.request_data?.error}
              />
            </div>
          ) : null}

          <div className="section_heading">Dashboard</div>
          <div className="section_content">
            <div className="each_item">
              <div className="item_heading">
                <div className="item_icon">
                  {" "}
                  <img src={UsrIco} alt="" />
                </div>
                <div className="item_title">MY PERSONAL DATA</div>
              </div>
              <div className="item_content">
                <div className="item_desc">View your biodata details here</div>
                <div className="item_link">
                  {" "}
                  <Link to="/undergraduate/personal-data">View</Link>{" "}
                </div>
              </div>
            </div>
            {/* <div className="each_item">
            <div className="item_heading">
              <div className="item_icon">
                {" "}
                <img src={UsrIco} alt="" />
              </div>
              <div className="item_title">COUNSELING DATA</div>
            </div>
            <div className="item_content">
              <div className="item_desc">
                View your counseling data details here
              </div>
              <div className="item_link">
                {" "}
                <Link to="/undergraduate/counselling-data">
                  View
                </Link>{" "}
              </div>
            </div>
          </div> */}
            <div className="each_item">
              <div className="item_heading">
                <div className="item_icon">
                  {" "}
                  <img src={Print} alt="" />
                </div>
                <div className="item_title">PRINT YOUR EXAM CARD</div>
              </div>
              <div className="item_content">
                <div className="item_desc">Print your Exam card here</div>
                <div className="item_link">
                  {" "}
                  <button
                    onClick={() => {
                      setShowExamCardErrorAlert(true);
                      if (
                        examCardError &&
                        examCardError?.request_data?.error?.length
                      ) {
                        setInfoMsg(examCardError?.request_data?.error);
                        setShow(true);
                      } else if (canPrintExamCard) {
                        downloadPdf();
                      }
                    }}
                  >
                    {downloading ? "Downloading..." : "Download"}
                  </button>
                  {/* <Link to="/undergraduate/print-exam-card">
                  Print
                </Link>{" "} */}
                </div>
              </div>
            </div>
            <div className="each_item">
              <div className="item_heading">
                <div className="item_icon">
                  {" "}
                  <img src={ExamCard} alt="" />
                </div>
                <div className="item_title">COURSE REGISTRATION</div>
              </div>
              <div className="item_content">
                <div className="item_link">
                  {" "}
                  <Link to="/undergraduate/print/course-reg">Print</Link>{" "}
                </div>
                <div className="item_link">
                  {" "}
                  <Link to="/undergraduate/course-reg">Register</Link>{" "}
                </div>
              </div>
            </div>
            <div className="each_item">
              <div className="item_heading">
                <div className="item_icon">
                  {" "}
                  <img src={Result} alt="" />
                </div>
                <div className="item_title">MY RESULT </div>
              </div>
              <div className="item_content">
                <div className="item_desc">
                  Print all your previous results here
                </div>
                <div className="item_link">
                  {" "}
                  <button
                    onClick={() => {
                      setInfoMsg("Results are not ready for viewing");
                      setShow(true);
                    }}
                  >
                    Download
                  </button>
                  {/* <Link to="/undergraduate/my-result">Download</Link>{" "} */}
                </div>
              </div>
            </div>
            <div className="each_item">
              <div className="item_heading">
                <div className="item_icon">
                  {" "}
                  <img src={House} alt="" />
                </div>
                <div className="item_title">HOSTEL ACCOMMODATION </div>
              </div>
              <div
                className={
                  !hasPaidHostelAccommodationFee
                    ? "paid_buttons item_content"
                    : "item_content"
                }
              >
                {hasPaidHostelAccommodationFee ? <button>Paid</button> : null}
                <div className="item_link">
                  {!hasBookedHostelAccommodation ? (
                    <Link
                      onClick={() => {
                        !isEligible ||
                        !isHostelOpen ||
                        isMedicalStudentMsg?.length
                          ? setShowNoSpaceModal(true)
                          : setShowNoSpaceModal(false);
                      }}
                      to={
                        isEligible && isHostelOpen
                          ? "/undergraduate/hostels"
                          : "/undergraduate/dashboard"
                      }
                    >
                      Book Hostel
                    </Link>
                  ) : hasBookedHostelAccommodation &&
                    !hasPaidHostelAccommodationFee ? (
                    <Link to="/undergraduate/hostel-fee">Pay</Link>
                  ) : hasBookedHostelAccommodation &&
                    hasPaidHostelAccommodationFee ? (
                    <Link to="/undergraduate/hostel-fee">Details</Link>
                  ) : null}
                </div>
              </div>
            </div>
            {/* <div className="each_item">
              <div className="item_heading">
                <div className="item_icon">
                  {" "}
                  <img src={Document} alt="e-documentation" />
                </div>
                <div className="item_title">e - DOCUMENTATION</div>
              </div>
              <div className="item_content">
                <div className="item_desc">Upload your documents here</div>
                <div className="item_link">
                  {" "}
                  <Link to="/undergraduate/e-documentation">View</Link>{" "}
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="each_dashboard_section">
          <div className="section_heading">Payments</div>
          {/* {acceptanceReceiptRequest?.request_status ==
            appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_SUCCESS && (
              <div className="alert-msg success">
                <div className="alert-txt">
                  You can now{" "}
                  <a
                    className="dl_link"
                    target="_blank"
                    href={acceptanceReceiptRequest?.request_data?.response}
                  >
                    click here to download the receipt
                  </a>{" "}
                </div>
              </div>
            )} */}
          {acceptanceReceiptRequest?.request_status ===
            appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_FAILURE && (
            <AlertMsg
              type="error"
              message={acceptanceReceiptRequest?.request_data.error}
            />
          )}
          <div className="section_content">
            {(dashboardRequestData?.level === "100" ||
              (dashboardRequestData?.level === "200" &&
                dashboardRequestData?.modeOfEntry === "DE")) && (
              <div className="payment_item">
                <div className="payment_type">
                  <div className="item_icon">
                    {" "}
                    <img src={PayIco} alt="" />
                  </div>
                  <div className="item_title">ACCEPTANCE FEE</div>
                </div>
                <div className="payment_status">
                  <div className="status_txt done"> Paid </div>

                  <div className=" item_link">
                    <button
                      disabled={acceptanceReceiptRequest?.is_request_processing}
                      onClick={downloadAcceptanceReceipt}
                    >
                      {acceptanceReceiptRequest?.is_request_processing
                        ? "Downloading..."
                        : "Download Receipt"}
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="payment_item">
              <div className="payment_type">
                <div className="item_icon">
                  {" "}
                  <img src={PayIco} alt="" />
                </div>
                <div className="item_title">SCHOOL FEE PAYMENT</div>
              </div>
              <div className="payment_status">
                {!dashboardRequestData?.hasPaidSchoolFee && (
                  <div className="status_txt pending"> Pending </div>
                )}
                {dashboardRequestData?.hasPaidSchoolFee && (
                  <div className="status_txt done"> Paid </div>
                )}
                {!dashboardRequestData?.hasPaidSchoolFee && (
                  <div className="pay_link">
                    {" "}
                    <Link to="/undergraduate/payments/school-fee">
                      Pay now
                    </Link>{" "}
                  </div>
                )}
                {dashboardRequestData?.hasPaidSchoolFee && (
                  <div className="pay_link ">
                    {/* <div className="pay_link details"> */}{" "}
                    <Link to="/undergraduate/payments/school-fee">Details</Link>{" "}
                  </div>
                )}
              </div>
            </div>
            {dashboardRequestData?.state &&
              dashboardRequestData?.state.toLowerCase() === "niger" && (
                <div className="payment_item">
                  <div className="payment_type">
                    <div className="item_icon">
                      {" "}
                      <img src={PayIco} alt="" />
                    </div>
                    <div className="item_title">NANISS PAYMENT</div>
                  </div>
                  <div className="payment_status">
                    {!dashboardRequestData?.hasPaidNanissFee && (
                      <div className="status_txt pending"> Pending </div>
                    )}
                    {dashboardRequestData?.hasPaidNanissFee && (
                      <div className="status_txt done"> Paid </div>
                    )}
                    {!dashboardRequestData?.hasPaidNanissFee && (
                      <div className="pay_link">
                        {/* <button
                      className="btn"
                      onClick={() => {
                        setInfoMsg("Please check back on how to make payment for NANISS Fee");
                        setShow(true);
                      }}
                    >
                      Download
                    </button> */}{" "}
                        <Link to="/undergraduate/payments/naniss-fee">
                          Pay now
                        </Link>{" "}
                      </div>
                    )}
                    {dashboardRequestData?.hasPaidNanissFee && (
                      <div className="pay_link ">
                        {/* <button
                      className="btn"
                      onClick={() => {
                        setInfoMsg("Please check back on how to make payment for NANISS Fee");
                        setShow(true);
                      }}
                    >
                      Download
                    </button> */}{" "}
                        <Link to="/undergraduate/payments/naniss-fee">
                          Details
                        </Link>{" "}
                      </div>
                    )}
                  </div>
                </div>
              )}
            <div className="payment_item">
              <div className="payment_type">
                <div className="item_icon">
                  {" "}
                  <img src={PayIco} alt="" />
                </div>
                <div className="item_title">FACULTY FEE PAYMENT</div>
              </div>
              <div className="payment_status">
                {!dashboardRequestData?.hasPaidFacultyFee && (
                  <div className="status_txt pending"> Pending </div>
                )}
                {dashboardRequestData?.hasPaidFacultyFee && (
                  <div className="status_txt done"> Paid </div>
                )}
                {!dashboardRequestData?.hasPaidFacultyFee && (
                  <div className="pay_link">
                    {/* <button
                    className="btn"
                    onClick={() => {
                      setInfoMsg("Please check back on how to make payment for Faculty Fee");
                      setShow(true);
                    }}
                  >
                    Download
                  </button> */}{" "}
                    <Link to="/undergraduate/payments/faculty-fee">
                      Pay now
                    </Link>{" "}
                  </div>
                )}
                {dashboardRequestData?.hasPaidFacultyFee && (
                  <div className="pay_link ">
                    {/* <button
                    className="btn"
                    onClick={() => {
                      setInfoMsg("Please check back on how to make payment for Faculty Fee");
                      setShow(true);
                    }}
                  >
                    Download
                  </button> */}{" "}
                    <Link to="/undergraduate/payments/faculty-fee">
                      Details
                    </Link>{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="payment_item">
              <div className="payment_type">
                <div className="item_icon">
                  {" "}
                  <img src={PayIco} alt="" />
                </div>
                <div className="item_title">SUG DUES PAYMENT</div>
              </div>
              <div className="payment_status">
                {!dashboardRequestData?.hasPaidSugFee && (
                  <div className="status_txt pending"> Pending </div>
                )}
                {dashboardRequestData?.hasPaidSugFee && (
                  <div className="status_txt done"> Paid </div>
                )}
                {!dashboardRequestData?.hasPaidSugFee && (
                  <div className="pay_link">
                    {/* <button
                    className="btn"
                    onClick={() => {
                      setInfoMsg("Please check back on how to make payment for SUG Fee");
                      setShow(true);
                    }}
                  >
                    Download
                  </button> */}{" "}
                    <Link to="/undergraduate/payments/sug-fee">Pay now</Link>{" "}
                  </div>
                )}
                {dashboardRequestData?.hasPaidSugFee && (
                  <div className="pay_link ">
                    {" "}
                    <Link to="/undergraduate/payments/sug-fee">
                      Details
                    </Link>{" "}
                    {/* <button
                    className="btn"
                    onClick={() => {
                      setInfoMsg("Please check back on how to make payment for SUG Fee");
                      setShow(true);
                    }}
                  >
                    Download
                  </button> */}
                  </div>
                )}
              </div>
            </div>
            {(Number(dashboardRequestData?.level) <= 300 ||
              (Number(dashboardRequestData?.level) > 300 && showGST)) && (
              <div className="payment_item">
                <div className="payment_type">
                  <div className="item_icon">
                    {" "}
                    <img src={PayIco} alt="" />
                  </div>
                  <div className="item_title">GST REG. FEE PAYMENT</div>
                </div>
                <div className="payment_status">
                  {!dashboardRequestData?.hasPaidGstFee && (
                    <div className="status_txt pending"> Pending </div>
                  )}
                  {dashboardRequestData?.hasPaidGstFee && (
                    <div className="status_txt done"> Paid </div>
                  )}
                  {!dashboardRequestData?.hasPaidGstFee && (
                    <div className="pay_link">
                      {" "}
                      <Link to="/undergraduate/payments/gst-fee">
                        Pay now
                      </Link>{" "}
                      {/* <button
                    className="btn"
                    onClick={() => {
                      setInfoMsg("Please check back on how to make payment for GST Fee");
                      setShow(true);
                    }}
                  >
                    Download
                  </button> */}
                    </div>
                  )}
                  {dashboardRequestData?.hasPaidGstFee && (
                    <div className="pay_link">
                      {" "}
                      <Link to="/undergraduate/payments/gst-fee">
                        Details
                      </Link>{" "}
                      {/* <button
                    className="btn"
                    onClick={() => {
                      setInfoMsg("Please check back on how to make payment for GST Fee");
                      setShow(true);
                    }}
                  >
                    Download
                  </button> */}
                    </div>
                  )}
                </div>
              </div>
            )}
            {isEntrepreneurFeeExempted.length === 0 && (
              <div className="payment_item">
                <div className="payment_type">
                  <div className="item_icon">
                    {" "}
                    <img src={PayIco} alt="" />
                  </div>
                  <div className="item_title">
                    ENTREPRENEURSHIP FEE PAYMENT{" "}
                  </div>
                </div>
                <div className="payment_status">
                  {!dashboardRequestData?.hasPaidEntrepreneurshipFee && (
                    <div className="status_txt pending"> Pending </div>
                  )}
                  {dashboardRequestData?.hasPaidEntrepreneurshipFee && (
                    <div className="status_txt done"> Paid </div>
                  )}
                  {!dashboardRequestData?.hasPaidEntrepreneurshipFee && (
                    <div className="pay_link">
                      {" "}
                      <Link to="/undergraduate/payments/entrepreneur-fee">
                        Pay now
                      </Link>{" "}
                      {/* <button
                    className="btn"
                    onClick={() => {
                      setInfoMsg("Please check back on how to make payment for Entrepreneurship Fee");
                      setShow(true);
                    }}
                  >
                    Download
                  </button> */}
                    </div>
                  )}
                  {dashboardRequestData?.hasPaidEntrepreneurshipFee && (
                    <div className="pay_link ">
                      {" "}
                      <Link to="/undergraduate/payments/entrepreneur-fee">
                        Details
                      </Link>{" "}
                      {/* <button
                    className="btn"
                    onClick={() => {
                      setInfoMsg("Please check back on how to make payment for Entrepreneurship Fee");
                      setShow(true);
                    }}
                  >
                    Download
                  </button> */}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const UGradDashboard = ({ pageProps }: any) => {
  const [showExamCard, setShowExamCard] = useState<boolean>(false);
  const [isEligible, setIsEligible] = useState(false);
  const [isHostelOpen, setIsHostelOpen] = useState(false);
  const [showGST, setShowGST] = useState(false);
  const [isMedicalStudentMsg, setIsMedicalStudentMsg] = useState("");

  let authInfo = pageProps.uGradLoginRequest.request_data;
  let dashboardRequest = pageProps.UGradProfileRequest;
  let dashboardRequestData =
    pageProps?.UGradProfileRequest?.request_data?.response;
  const studentId =
    pageProps?.uGradLoginRequest?.request_data?.response?.profile
      ?.studentUniqueId;
  const studentLevel =
    pageProps?.uGradLoginRequest?.request_data?.response?.profile?.level;
  const availabilityCheck = async (studentId: string) => {
    const res = await checkEligibility(studentId);
    if (res?.status === 404) {
      setIsEligible(false);
    } else if (res?.status === 200 && !res?.data?.isMedicalStudent) {
      setIsEligible(true);
    } else if (res?.status === 200 && res?.data?.isMedicalStudent) {
      setIsMedicalStudentMsg(res?.data?.message);
    }
  };
  const checkAdditionalFeePayment = async (studentId: string) => {
    await uGradPortal
      .checkStudentAdditionalPayment(studentId)
      .then((data) => {
        if (data?.status === 200) {
          setShowGST(true);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setShowGST(false);
        }
        handleRequestErrors(err);
      });
  };
  const checkIsHostelOpen = useCallback(async () => {
    const res = await getCurrentSchoolSession();
    setIsHostelOpen(res?.data?.isHostelAccommodationOpen);
  }, []);

  useEffect(() => {
    if (studentLevel && Number(studentLevel) >= 400) {
      checkAdditionalFeePayment(studentId);
    }
    availabilityCheck(studentId);
    checkIsHostelOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let payload = {
      matricNumber: authInfo?.matricNumber,
    };

    getUnderGradProfileActionRequest({
      pageProps,
      payload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let payload = {
      matricNumber: authInfo?.matricNumber,
      session: authInfo?.response?.profile?.currentSession,
      studentId: authInfo?.response?.profile?.studentUniqueId,
    };

    getUnderGradExamCardActionRequest({
      pageProps,
      payload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExamCard]);

  const downloadAcceptanceReceipt = () => {
    let payload = {
      matricNumber: authInfo?.matricNumber,
    };
    getUnderGradAcceptanceFeeReceiptActionRequest({
      pageProps,
      payload,
    });
  };

  return (
    <div className="dashboard_wrap">
      {dashboardRequest?.request_status ===
        appConstantsUGradOPortal?.GET_PROFILE_DATA_PENDING && (
        <>
          <LoadingItem />
        </>
      )}
      {dashboardRequest?.request_status ===
        appConstantsUGradOPortal?.GET_PROFILE_DATA_SUCCESS &&
        dashboardRequestData && (
          <DashboardContent
            pageProps={pageProps}
            dashboardRequestData={dashboardRequestData}
            showExamCard={showExamCard}
            setShowExamCard={setShowExamCard}
            downloadAcceptanceReceipt={downloadAcceptanceReceipt}
            isEligible={isEligible}
            showGST={showGST}
            isHostelOpen={isHostelOpen}
            isMedicalStudentMsg={isMedicalStudentMsg}
          />
        )}
      {dashboardRequest?.request_status ===
        appConstantsUGradOPortal?.GET_PROFILE_DATA_FAILURE && (
        <div className="dashboard_content justify-center">
          <AlertMsg
            type="error"
            message={dashboardRequest?.request_data.error}
          />
        </div>
      )}
    </div>
  );
};

const UGradDashboardContent = ({ pageProps }: any) => {
  useEffect(() => {
    getUnderGradAcceptanceFeeReceiptActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    getUnderGradExamCardActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="biodata_info">
      <UGradDashboard pageProps={pageProps} />
    </div>
  );
};

const UGradDashboardWrap = (pageProps: any) => {
  return (
    <UndergraduatePortal
      childComponent={<UGradDashboardContent pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  getUnderGradProfile: uGradPortal.UnderGradProfile,
  getUnderGradExamCardReg: uGradPortal.UnderGradPrintExamCardReg,
  getUnderGradAcceptanceFeeReceipt:
    uGradPortal.UnderGradGetAcceptanceFeeReceipt,
};
const mapStateToProps = (state: any) => ({
  UGradProfileRequest: state.allUGradPortalReducers.UGradProfile,
  UGradExamCardRequest: state.allUGradPortalReducers.UnderGradPrintExamCard,
  UGradGenerateAcceptanceFeeRequest:
    state.allUGradPortalReducers.UGradGenerateAcceptanceFee,
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});
export default connect(mapStateToProps, mapDispatchToProps)(UGradDashboardWrap);
