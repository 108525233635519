import { useCallback, useEffect, useState } from "react";
import "../student-payments/index.scss";
import {
  accomodationConfirmation,
  getOrGenerateHostelFee,
  getStudentHostelBooking,
} from "../../../redux/action/hostels";
import { useSelector } from "react-redux";
import { history } from "../../../shared/_helpers/history";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { LoadingItem } from "../../../shared/components/loading";
import { Link } from "react-router-dom";

const InvoiceContent = () => {
  const [invoiceDetails, setInvoiceDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { UGradProfile } = useSelector(
    (state: any) => state.allUGradPortalReducers
  );
  let authInfo = UGradProfile?.request_data?.response;
  const screenResolution: any = useWindowSize();

  const {
    studentUniqueId,
    matricNumber,
    level,
    fullName,
    session,
    email,
    phoneNumber,
    faculty,
    department,
    programme,
    hasBookedHostelAccommodation,
  } = authInfo || {};

  const generateInvoice = useCallback(async () => {
    setLoading(true);
    const response = await getStudentHostelBooking(studentUniqueId);
    let hostelSiteName = "";
    let hostelBlockName = "";
    if (response?.status === 200) {
      hostelSiteName = response?.data?.hostelSite;
      hostelBlockName = response?.data?.blockName;
    }
    const invoicePayload = {
      studentUniqueId: studentUniqueId,
      matricNumber,
      level,
      fullName,
      session,
      email,
      phoneNumber,
      faculty,
      department,
      programme,
      hostelSiteName,
      hostelBlockName,
    };

    const paymentDetails = await getOrGenerateHostelFee(invoicePayload);
   
   const confirmation = await accomodationConfirmation({
      studentUniqueId,
      department,
    });
    if (paymentDetails?.status === 200) {
      const {
        compulsoryFeeHistories,
        paymentStatus,
        amount,
        paymentType,
        transactionId,
        downloadUrl,
        session,
       
      } = paymentDetails?.data;
      setInvoiceDetails({
        compulsoryFeeHistories,
        paymentStatus,
        amount,
        paymentType,
        transactionId,
        downloadUrl,
        session,
        confirmation: confirmation?.data?.downloadUrl,
      });
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 


  
  useEffect(() => {
    if (!hasBookedHostelAccommodation) {
      return history.push("/undergraduate/dashboard");
    }
    generateInvoice();
  }, [hasBookedHostelAccommodation, generateInvoice]);

  return (
    <>
      {!hasBookedHostelAccommodation ? null : hasBookedHostelAccommodation &&
        loading ? (
        <LoadingItem />
      ) : (
        <div className="payments_info_wrap">
          <div className="payment_details">
            <div className="txtn_info_wrap">
              <div className="each_info_detail">
                <div className="info_title">SESSION :</div>
                <div className="info_data">{invoiceDetails.session}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">TRANSACTION ID :</div>
                <div className="info_data">{invoiceDetails?.transactionId}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">MATRIC NO :</div>
                <div className="info_data">{matricNumber}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">LEVEL :</div>
                <div className="info_data">{level}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">STUDENT NAME :</div>
                <div className="info_data">{fullName}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">SERVICE :</div>
                <div className="info_data">{invoiceDetails?.paymentType}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">TOTAL AMOUNT PAYABLE :</div>
                <div className="info_data">{invoiceDetails?.amount}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">PAYMENT STATUS :</div>
                <div className="info_data">{invoiceDetails?.paymentStatus}</div>
              </div>
            </div>
            {invoiceDetails?.paymentStatus !== "PAID" && (
              <div className="txtn_cta">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="btn"
                  href={invoiceDetails?.downloadUrl}
                >
                  Download Invoice
                </a>
              </div>
            )}

            {invoiceDetails?.compulsoryFeeHistories?.length >= 1 && (
              <div className="previous_payments">
                {screenResolution?.width >= 767 && (
                  <table>
                    <thead>
                      <tr>
                        <th className="first">
                          <span> GENERATED ON</span>
                        </th>
                        <th>
                          {" "}
                          <span>SESSION</span>
                        </th>
                        <th>
                          {" "}
                          <span>TRANSACTION ID</span>
                        </th>
                        <th>
                          {" "}
                          <span>PAYMENT STATUS</span>
                        </th>
                        <th>
                          {" "}
                          <span>AMOUNT</span>
                        </th>
                        <th className="last">
                          <span>RECEIPT</span>
                        </th>
                        <th className="last">
                          <span>CONFIRMATION</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetails?.compulsoryFeeHistories.map(
                        (txtn: any, index: any) => {
                          return (
                            <tr>
                              <td className="first">
                                <span>{txtn?.generatedOn}</span>
                              </td>
                              <td>
                                {" "}
                                <span>{txtn?.session}</span>
                              </td>
                              <td>
                                {" "}
                                <span>{txtn?.transactionId}</span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span
                                  className={
                                    txtn?.paymentStatus.toLowerCase() === "paid"
                                      ? "done"
                                      : "pending"
                                  }
                                >
                                  {txtn?.paymentStatus}
                                </span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span>{txtn?.amount}</span>{" "}
                              </td>
                              <td className="last">
                                <span>
                                  {" "}
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={txtn?.downloadUrl}
                                    className="download_cta btn"
                                  >
                                    Download Receipt
                                  </a>
                                </span>
                              </td>

                              <td className="last">
                                <span>
                                  {" "}
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={invoiceDetails?.confirmation}
                                    className="download_cta btn"
                                  >
                                    Download Confirmation
                                  </a>
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                )}

                {screenResolution?.width <= 766 && (
                  <div className="past_data">
                    {invoiceDetails?.compulsoryFeeHistories.map(
                      (txtn: any, index: any) => {
                        return (
                          <div className="each_history_data">
                            <div className="top_data">
                              <div className="left">
                                {" "}
                                <span>Generated on:</span> {txtn?.generatedOn}
                              </div>
                              <div className="right">
                                {" "}
                                <span
                                  className={
                                    txtn?.paymentStatus.toLowerCase() === "paid"
                                      ? "done status"
                                      : "pending status"
                                  }
                                >
                                  {txtn?.paymentStatus}
                                </span>
                              </div>
                            </div>
                            <div className="other_data">
                              <div className="left">
                                <div className="title">Amount</div>
                                <div className="value">{txtn?.amount}</div>
                              </div>
                              <div className="right">
                                <div className="title">Transaction ID</div>
                                <div className="value">
                                  {txtn?.transactionId}
                                </div>
                              </div>
                            </div>
                            <div className="other_data">
                              <div className="left">
                                <div className="title">Session</div>
                                <div className="value">{txtn?.session}</div>
                              </div>
                              <div className="right">
                                <div className="value">
                                  <a
                                    href={txtn?.downloadUrl}
                                    className="download_cta btn"
                                  >
                                    Download Receipt
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="goback_cta">
              <Link to="/undergraduate/dashboard" className="btn">
                Back
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceContent;
