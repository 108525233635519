import { connect } from "react-redux";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {
  resetReload,
  uGradOnboarding,
} from "../../../../redux/action/onboarding/undergrad";
import InfoModal from "../../../../modules/undergraduate/admissions/check-admission-status/components/InfoModal";
import Logo from "../../../../assets/logo2.png";
import UserIco from "../../../../assets/user-2.png";
import "./index.scss";
import generatePDF from "react-to-pdf";
import Navigation from "../../navigation/Navigation";

const AppHeader = (props: any) => {
  const [show, setShow] = useState<boolean>(false);
  const [infoMsg, setInfoMsg] = useState<string>("");
  let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  const matricNumber = loggedAccountInfo?.profile?.matricNumber || "";
  const formattedMatricNumber = matricNumber.replace(/\//g, "");
  const options: any = {
    filename: `${formattedMatricNumber}-exam-card.pdf`,
    page: {
      orientation: "landscape",
    },
  };

  let profile = loggedAccountInfo.profile;
  const location: any = useLocation();

  const logout = () => {
    props.logoutAction(true);
  };

  const getTargetElement = (): HTMLElement | null =>
    document.getElementById("table-to-export");

  const downloadPdf = async () => {
    await generatePDF(getTargetElement, options);
  };
  return (
    <div className="header-container">
      <InfoModal show={show} setShow={setShow} message={infoMsg} />
      <div className="ug_portal_header">
        <div className="left_wrap">
          <div className="logo-wrap">
            <a href="https://ibbu.edu.ng/" target="_blank" rel="noreferrer">
              <img src={Logo} alt="Admission Logo" />
            </a>
          </div>
          <div className="head_txt_wrap">
            <div className="portal_mode">Undergraduate Student Portal</div>
            <div className="school_name_txt">
              Ibrahim Badamasi Babangida University
            </div>
          </div>
        </div>
        <div className="user_info_wrap">
          {profile && (
            <Dropdown className="report_dropdown">
              <Dropdown.Toggle id="dropdown-basic" className="action-cta">
                <div className="profile_drop">
                  <div className="user_ico">
                    <img src={profile?.photoId || UserIco} alt="" />
                  </div>
                  {profile?.firstName} {profile?.surName}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={`/undergraduate/dashboard`}>
                  {" "}
                  Dashboard
                </Dropdown.Item>
                <Dropdown.Item href={`/undergraduate/personal-data`}>
                  {" "}
                  My Personal Data
                </Dropdown.Item>
                <Dropdown.Item onClick={downloadPdf}>
                  {" "}
                  Print Your Exam Card
                </Dropdown.Item>
                {/* <Dropdown.Item href={`/student/undergraduate/personal-data`}> Counselling Data</Dropdown.Item> */}
                <Dropdown.Item href={`/undergraduate/course-reg`}>
                  {" "}
                  Course Registration
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setInfoMsg("Results are not ready for viewing");
                    setShow(true);
                  }}
                >
                  {" "}
                  My Result
                </Dropdown.Item>
                <Dropdown.Item href={"/undergraduate/hostels"}>
                  {" "}
                  Hostel Accomodation
                </Dropdown.Item>
                <Dropdown.Item href={`/undergraduate/update-password`}>
                  {" "}
                  Update Password
                </Dropdown.Item>
               
                <Dropdown.Item
                  onClick={() => {
                    resetReload();
                    logout();
                  }}
                >
                  {" "}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      {location && !location.pathname.includes("dashboard") ? (
        <div className="heading_wrap">
          <div className="heading-items">
            <Navigation to="/undergraduate/dashboard" />
            <h4>{props.title}</h4>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = {
  logoutAction: uGradOnboarding.Logout,
};
const mapStateToProps = (state: any) => ({
  UGradProfileRequest: state.allUGradPortalReducers.UGradProfile,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
