import { useEffect } from "react";
import { useSelector } from "react-redux";
import { UndergraduatePortal } from "../../../shared/templates/portal/undergraduate";
import HostelsList from "./hostelBooking";
import "./index.scss";
import { ToastContainer } from "react-toastify";
import { history } from "../../../shared/_helpers/history";

const Hostels = () => {
  const { UGradProfile } = useSelector(
    (state: any) => state.allUGradPortalReducers
  );

  let authInfo = UGradProfile?.request_data?.response;
  useEffect(() => {
    if (authInfo?.hasBookedHostelAccommodation) {
      history.push("/undergraduate/hostel-fee");
    }
  }, [authInfo]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <UndergraduatePortal
        title="Hostel accomodation"
        childComponent={<HostelsList student={authInfo} />}
      />
    </>
  );
};

export default Hostels;
